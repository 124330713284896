// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__SortingModalTrigger__sortingModalTrigger{background:none;box-shadow:none;color:var(--GW-TEXT-COLOR-1)}.jut__SortingModalTrigger__sortingModalTrigger:focus,.jut__SortingModalTrigger__sortingModalTrigger:hover{background-color:var(--GW-CLICKABLE-ELEMENT-BACKGROUND-COLOR-ACTIVE)}.jut__SortingModalTrigger__sortingModalTrigger:not(:disabled){cursor:pointer}.jut__SortingModalTrigger__sortingModalTrigger .jut__SortingModalTrigger__sortingModalTriggerIcon{font-size:var(--GW-ICON-FONT-SIZE-3)}", "",{"version":3,"sources":["webpack://./../../../../../src/dataview/features/sorting/ListViewSortingModal/SortingModalTrigger.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/sorting/ListViewSortingModal/SortingModalTrigger.module.css"],"names":[],"mappings":"AAAA,+CACE,eAAA,CACA,eAAA,CACA,4BCCF,CDCE,0GAEE,oECAJ,CDGE,8DACE,cCDJ,CDIE,kGACE,oCCFJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortingModalTrigger": "jut__SortingModalTrigger__sortingModalTrigger",
	"sortingModalTriggerIcon": "jut__SortingModalTrigger__sortingModalTriggerIcon"
};
export default ___CSS_LOADER_EXPORT___;
