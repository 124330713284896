// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__HelpElement__helpComponent{color:var(--GW-HELP-POPOVER-COLOR);font-size:var(--GW-FONT-SIZE-BODY);line-height:var(--GW-LINE-HEIGHT-BODY);margin:var(--GW-SPACING-2) 0}.jut__HelpElement__helpLink{color:var(--GW-HELP-POPOVER-LINK-COLOR)}", "",{"version":3,"sources":["webpack://./../../../src/components/HelpElement/HelpElement.module.scss","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss","webpack://./../../platform/@jutro/legacy/components/HelpElement/HelpElement.module.css"],"names":[],"mappings":"AAEA,iCAEE,kCAAA,CCsBA,kCAAA,CACA,sCAAA,CDxBA,4BEKF,CFCA,4BACE,uCEEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpComponent": "jut__HelpElement__helpComponent",
	"helpLink": "jut__HelpElement__helpLink"
};
export default ___CSS_LOADER_EXPORT___;
