// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__MultipleFilesUploadAccordion__statusBarAccordion{background-color:var(--GW-BACKGROUND-COLOR);margin-bottom:var(--GW-SPACING-3)}.jut__MultipleFilesUploadAccordion__statusBarAccordionCard.jut__MultipleFilesUploadAccordion__statusBarAccordionCard{border-left:none;border-right:none}.jut__MultipleFilesUploadAccordion__statusBarAccordionCardBody{margin:0}.jut__MultipleFilesUploadAccordion__container{background-color:var(--GW-BACKGROUND-COLOR);max-height:var(--GW-FILE-UPLOAD-FILE-LIST-MAX-HEIGHT);overflow-y:auto}", "",{"version":3,"sources":["webpack://./../../../../../src/components/FileUploadField/MultipleFilesUploadField/components/MultipleFilesUploadAccordion.module.scss","webpack://./../../platform/@jutro/legacy/components/FileUploadField/MultipleFilesUploadField/components/MultipleFilesUploadAccordion.module.css"],"names":[],"mappings":"AAAA,uDACE,2CAAA,CACA,iCCCF,CDEA,qHACE,gBAAA,CACA,iBCCF,CDEA,+DACE,QCCF,CDEA,8CAGE,2CAAA,CAFA,qDAAA,CACA,eCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusBarAccordion": "jut__MultipleFilesUploadAccordion__statusBarAccordion",
	"statusBarAccordionCard": "jut__MultipleFilesUploadAccordion__statusBarAccordionCard",
	"statusBarAccordionCardBody": "jut__MultipleFilesUploadAccordion__statusBarAccordionCardBody",
	"container": "jut__MultipleFilesUploadAccordion__container"
};
export default ___CSS_LOADER_EXPORT___;
