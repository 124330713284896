// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__OverlayContent__loading,.jut__OverlayContent__loading svg{font-size:var(--GW-FONT-SIZE-H2);font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD)}.jut__OverlayContent__loading svg{color:var(--GW-TEXT-COLOR-1);height:1.5em}", "",{"version":3,"sources":["webpack://./../../../../../src/dataview/features/overlay/OverlayContent/OverlayContent.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/overlay/OverlayContent/OverlayContent.module.css"],"names":[],"mappings":"AAIE,gEAHA,gCAAA,CACA,2CCOF,CDLE,kCAGE,4BAAA,CACA,YCCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "jut__OverlayContent__loading"
};
export default ___CSS_LOADER_EXPORT___;
