// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__FieldMessage__fieldValidationMessage{display:flex;flex-direction:column;font-size:var(--GW-FONT-SIZE-BODY);font-weight:var(--GW-FONT-WEIGHT-REGULAR);line-height:var(--GW-LINE-HEIGHT-BODY);margin-top:var(--GW-SPACING-1)}.jut__FieldMessage__fieldValidationMessage:empty{display:none}.jut__FieldMessage__fieldMessage{position:inherit}.jut__FieldMessage__error{color:var(--GW-COLOR-ERROR)}.jut__FieldMessage__error,.jut__FieldMessage__warning{margin-right:var(--GW-SPACING-2)}.jut__FieldMessage__warning{color:var(--GW-COLOR-WARNING)}.jut__FieldMessage__success{color:var(--GW-FIELD-COMPONENT-VALID-COLOR);margin-right:var(--GW-SPACING-2)}.jut__FieldMessage__card.jut__FieldMessage__card{border:none;border-radius:var(--GW-BORDER-RADIUS);box-shadow:var(--GW-SHADOW-2);margin-top:var(--GW-SPACING-1);padding:var(--GW-SPACING-2) var(--GW-SPACING-3)}.jut__FieldMessage__iconWrapper{align-items:center;display:flex}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/inputs/FieldComponent/FieldMessage.module.scss","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss","webpack://./../../platform/@jutro/components/widgets/inputs/FieldComponent/FieldMessage.module.css"],"names":[],"mappings":"AAEA,2CAGE,YAAA,CACA,qBAAA,CCoBA,kCAAA,CDvBA,yCAAA,CCwBA,sCAAA,CDvBA,8BEMF,CFAE,iDACE,YEEJ,CFEA,iCACE,gBECF,CFEA,0BACE,2BEEF,CFEA,sDAHE,gCEMF,CFHA,4BACE,6BEEF,CFEA,4BACE,2CAAA,CACA,gCECF,CFEA,iDACE,WAAA,CACA,qCAAA,CACA,6BAAA,CAEA,8BAAA,CADA,+CEEF,CFEA,gCAEE,kBAAA,CADA,YEEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldValidationMessage": "jut__FieldMessage__fieldValidationMessage",
	"fieldMessage": "jut__FieldMessage__fieldMessage",
	"error": "jut__FieldMessage__error",
	"warning": "jut__FieldMessage__warning",
	"success": "jut__FieldMessage__success",
	"card": "jut__FieldMessage__card",
	"iconWrapper": "jut__FieldMessage__iconWrapper"
};
export default ___CSS_LOADER_EXPORT___;
