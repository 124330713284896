// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TabSet__tabSet{display:flex;flex-direction:column;min-width:var(--JDS-TAB-SET-MIN-WIDTH,0);position:relative;width:var(--JDS-TAB-SET-WIDTH,100%)}.jut__TabSet__tabSet hr.jut__TabSet__divider{border-color:var(--JDS-TAB-HEADER-COLOR-BORDER,var(--GW-SEPARATOR-COLOR));border-style:solid;border-width:var(--JDS-TAB-HEADER-BORDER-WIDTH,0 0 1px 0);margin:0}.jut__TabSet__tabSet :focus,.jut__TabSet__tabSet:focus{outline:none}.jut__TabSet__frame{border-color:var(--JDS-TAB-FRAME-COLOR-BORDER,var(--JDS-COLOR-BORDER-REGULAR,var(--GW-BORDER-COLOR)));border-radius:var(--JDS-BORDER-RADIUS-REGULAR,var(--GW-BORDER-RADIUS));border-style:solid;border-width:var(--JDS-TAB-FRAME-BORDER-WIDTH,1px)}.jut__TabSet__content{background:var(--JDS-TAB-CONTENT-COLOR-BACKGROUND,var(--JDS-TAB-BAR-COLOR-BACKGROUND-DEPRECATED,var(--GW-TAB-BAR-BACKGROUND-COLOR)));border-radius:var(--JDS-TAB-CONTENT-BORDER-RADIUS);font-size:var(--JDS-TAB-CONTENT-FONT-SIZE,var(--GW-FONT-SIZE-BODY));line-height:var(--JDS-TAB-CONTENT-LINE-HEIGHT,var(--GW-LINE-HEIGHT-BODY));margin:var(--JDS-TAB-CONTENT-MARGIN,0);padding:var(--JDS-TAB-CONTENT-PADDING)}", "",{"version":3,"sources":["webpack://./../../../src/widgets/tab/TabSet.module.scss","webpack://./../../platform/@jutro/components/widgets/tab/TabSet.module.css"],"names":[],"mappings":"AAUA,qBACE,YAAA,CACA,qBAAA,CAGA,wCAAA,CAFA,iBAAA,CACA,mCCLF,CDQE,6CAIE,yEAAA,CADA,kBAAA,CADA,yDAAA,CADA,QCHJ,CDSE,uDAEE,YCRJ,CDYA,oBAGE,qGAAA,CAIA,sEAAA,CALA,kBAAA,CADA,kDCNF,CDeA,sBAEE,oIAAA,CAQA,kDAAA,CACA,mEAAA,CACA,yEAAA,CAXA,sCAAA,CAQA,sCCfF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabSet": "jut__TabSet__tabSet",
	"divider": "jut__TabSet__divider",
	"frame": "jut__TabSet__frame",
	"content": "jut__TabSet__content"
};
export default ___CSS_LOADER_EXPORT___;
