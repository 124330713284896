// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Main__main:focus{outline:none}.jut__Main__container{margin-bottom:var(--GW-LAYOUT-10);margin-top:var(--GW-LAYOUT-10)}@media (max-width:580px){.jut__Main__container{margin-bottom:var(--GW-LAYOUT-2);margin-top:0}}.jut__Main__container.jut__Main__fluid{margin:0;max-width:100%}.jut__Main__container.jut__Main__fluid:after,.jut__Main__container.jut__Main__fluid:before{margin-left:0}", "",{"version":3,"sources":["webpack://./../../../src/components/main/Main.module.scss","webpack://./../../platform/@jutro/legacy/components/main/Main.module.css","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAEA,uBACE,YCEF,CDCA,sBAEE,iCAAA,CADA,8BCGF,CCoCI,yBFxCJ,sBAMI,gCAAA,CADA,YCIF,CACF,CDDE,uCAEE,QAAA,CADA,cCIJ,CDDI,2FAEE,aCEN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "jut__Main__main",
	"container": "jut__Main__container",
	"fluid": "jut__Main__fluid"
};
export default ___CSS_LOADER_EXPORT___;
