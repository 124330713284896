// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Title__title{--GW-TEXT-COLOR-1:var(--GW-TABLE-VIEW-TITLE-BAR-COLOR);flex-shrink:2;overflow:hidden}@media (max-width:580px){.jut__Title__title{flex-shrink:1}}", "",{"version":3,"sources":["webpack://./../../../../src/dataview/features/title/Title.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/title/Title.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAEA,mBACE,sDAAA,CAEA,aAAA,CACA,eCCF,CCuCI,yBF5CJ,mBAOI,aCEF,CACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "jut__Title__title"
};
export default ___CSS_LOADER_EXPORT___;
