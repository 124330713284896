// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__SettingsCard__settingsCard.jut__SettingsCard__settingsCardContainer{border:none;box-shadow:var(--GW-SHADOW-2)}.jut__SettingsCard__settingsCard .jut__SettingsCard__settingsCardContent{padding-top:var(--GW-LAYOUT-3)}", "",{"version":3,"sources":["webpack://./../../../src/components/settingsCards/SettingsCard.module.scss","webpack://./../../platform/@jutro/legacy/components/settingsCards/SettingsCard.module.css"],"names":[],"mappings":"AACE,0EACE,WAAA,CACA,6BCAJ,CDGE,yEACE,8BCDJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsCard": "jut__SettingsCard__settingsCard",
	"settingsCardContainer": "jut__SettingsCard__settingsCardContainer",
	"settingsCardContent": "jut__SettingsCard__settingsCardContent"
};
export default ___CSS_LOADER_EXPORT___;
