// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__IFrame__iframeContainer{height:0;position:relative;width:100%}.jut__IFrame__iframe{border:1px solid var(--GW-BORDER-COLOR);border-radius:var(--GW-BORDER-RADIUS);height:100%;left:0;position:absolute;top:0;width:100%}.jut__IFrame__noBorder{border:none}.jut__IFrame__loader{background-color:rgba(0,0,0,.7);height:100%;position:absolute;width:100%}.jut__IFrame__aspect-ratio-4x3{padding-bottom:75%}.jut__IFrame__aspect-ratio-16x9{padding-bottom:56.25%}.jut__IFrame__aspect-ratio-17x9{padding-bottom:52.9411764706%}.jut__IFrame__aspect-ratio-2x1{padding-bottom:50%}", "",{"version":3,"sources":["webpack://./../../../src/widgets/IFrame/IFrame.module.scss","webpack://./../../platform/@jutro/components/widgets/IFrame/IFrame.module.css"],"names":[],"mappings":"AAAA,8BAGE,QAAA,CAFA,iBAAA,CACA,UCEF,CDEA,qBAOE,uCAAA,CADA,qCAAA,CAHA,WAAA,CACA,MAAA,CAHA,iBAAA,CAIA,KAAA,CAHA,UCMF,CDEA,uBACE,WCCF,CDEA,qBAKE,+BAAA,CAFA,WAAA,CAFA,iBAAA,CACA,UCGF,CDGA,+BACE,kBCAF,CDGA,gCACE,qBCAF,CDGA,gCACE,6BCAF,CDGA,+BACE,kBCAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframeContainer": "jut__IFrame__iframeContainer",
	"iframe": "jut__IFrame__iframe",
	"noBorder": "jut__IFrame__noBorder",
	"loader": "jut__IFrame__loader",
	"aspect-ratio-4x3": "jut__IFrame__aspect-ratio-4x3",
	"aspect-ratio-16x9": "jut__IFrame__aspect-ratio-16x9",
	"aspect-ratio-17x9": "jut__IFrame__aspect-ratio-17x9",
	"aspect-ratio-2x1": "jut__IFrame__aspect-ratio-2x1"
};
export default ___CSS_LOADER_EXPORT___;
