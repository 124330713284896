// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Cells__cell{height:var(--GW-TABLE-VIEW-ROW-HEIGHT);padding-left:var(--GW-SPACING-4);padding-right:var(--GW-SPACING-4);white-space:nowrap}.jut__Cells__cell [class*=__controlReadOnly]{font-weight:var(--GW-FONT-WEIGHT-REGULAR)}.jut__Cells__cell:focus{box-shadow:inset 0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);outline:none}.jut__Cells__headerCell{border-bottom:var(--GW-TABLE-VIEW-TABLE-SEPARATOR);font-weight:var(--GW-FONT-WEIGHT-BOLD)}.jut__Cells__interactiveHeaderCell{cursor:pointer}.jut__Cells__interactiveCell{flex:calc(var(--GW-SPACING-4)*2 + var(--GW-CHECKBOX-SIZE)) 0 auto;max-width:calc(var(--GW-SPACING-4)*2 + var(--GW-CHECKBOX-SIZE));width:calc(var(--GW-SPACING-4)*2 + var(--GW-CHECKBOX-SIZE))}.jut__Cells__tableActionsCell{width:1%}", "",{"version":3,"sources":["webpack://./../../../../src/dataview/TableView/cells/Cells.module.scss","webpack://./../../platform/@jutro/legacy/dataview/TableView/cells/Cells.module.css"],"names":[],"mappings":"AAAA,kBACE,sCAAA,CACA,gCAAA,CACA,iCAAA,CACA,kBCCF,CDCE,6CACE,yCCCJ,CDEE,wBAEE,sDAAA,CADA,YCCJ,CDIA,wBAEE,kDAAA,CADA,sCCAF,CDIA,mCACE,cCDF,CDIA,6BACE,iEAAA,CAEA,+DAAA,CADA,2DCAF,CDIA,8BAEE,QCFF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "jut__Cells__cell",
	"headerCell": "jut__Cells__headerCell",
	"interactiveHeaderCell": "jut__Cells__interactiveHeaderCell",
	"interactiveCell": "jut__Cells__interactiveCell",
	"tableActionsCell": "jut__Cells__tableActionsCell"
};
export default ___CSS_LOADER_EXPORT___;
