// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__LanguageSelector__toggleButton{background-color:var(--JDS-INPUT-FIELD-COLOR-BACKGROUND,var(--GW-FIELD-COMPONENT-BACKGROUND-COLOR));border:1px solid var(--GW-BORDER-COLOR);border-radius:var(--GW-BORDER-RADIUS);color:var(--JDS-INPUT-FIELD-TEXT-COLOR-TEXT,var(--GW-FIELD-COMPONENT-COLOR));font-weight:400;padding-left:var(--GW-SPACING-4);padding-right:var(--GW-SPACING-4)}.jut__LanguageSelector__toggleButton:after{border:1px solid;border-width:1px 1px 0 0;content:\"\";display:block;height:5px;margin-left:var(--GW-SPACING-3);transform:translateY(-1px) rotate(135deg);width:5px}.jut__LanguageSelector__toggleButton:active,.jut__LanguageSelector__toggleButton:active:focus:hover,.jut__LanguageSelector__toggleButton:active:hover,.jut__LanguageSelector__toggleButton:hover{background-color:var(--JDS-INPUT-FIELD-COLOR-BACKGROUND,var(--GW-FIELD-COMPONENT-BACKGROUND-COLOR))}.jut__LanguageSelector__hideDropdownIndicator:after{display:none}", "",{"version":3,"sources":["webpack://./../../../src/widgets/LanguageSelector/LanguageSelector.module.scss","webpack://./../../platform/@jutro/components/widgets/LanguageSelector/LanguageSelector.module.css"],"names":[],"mappings":"AAAA,qCAKE,mGAAA,CAIA,uCAAA,CACA,qCAAA,CATA,4EAAA,CAUA,eAAA,CACA,gCAAA,CACA,iCCLF,CDOE,2CAME,gBAAA,CAAA,wBAAA,CAJA,UAAA,CADA,aAAA,CAGA,UAAA,CAIA,+BAAA,CADA,yCAAA,CAJA,SCAJ,CDQE,iMAIE,mGCTJ,CDiBE,oDACE,YCdJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleButton": "jut__LanguageSelector__toggleButton",
	"hideDropdownIndicator": "jut__LanguageSelector__hideDropdownIndicator"
};
export default ___CSS_LOADER_EXPORT___;
