// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__CustomInput__inputWrapper input[aria-disabled=true]{cursor:not-allowed}.jut__CustomInput__inputWrapper button[aria-disabled=true]{cursor:not-allowed;opacity:1}.jut__CustomInput__inputContainer{align-items:center;display:inline-flex;padding:0;transition:box-shadow var(--GW-ANIMATION-DURATION)}.jut__CustomInput__controlFocused{border-color:var(--JDS-COLOR-BORDER-FOCUS,var(--GW-FIELD-COMPONENT-BORDER-COLOR-FOCUS));box-shadow:0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);outline:none}.jut__CustomInput__customInput{background-color:var(--JDS-INPUT-FIELD-COLOR-BACKGROUND,var(--GW-FIELD-COMPONENT-BACKGROUND-COLOR));border:none;border-radius:var(--GW-BORDER-RADIUS);box-shadow:none;color:var(--JDS-INPUT-FIELD-TEXT-COLOR-TEXT,var(--GW-FIELD-COMPONENT-COLOR));font-weight:var(--GW-FONT-WEIGHT-REGULAR);height:100%;outline:none;padding:var(--GW-SPACING-2) 2rem var(--GW-SPACING-2) .75rem;text-align:inherit;text-overflow:ellipsis;width:100%}.jut__CustomInput__customInput[disabled]{cursor:not-allowed}.jut__CustomInput__icon:disabled{opacity:1}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/inputs/date/GenericDatePicker/CustomInput.module.scss","webpack://./../../platform/@jutro/components/widgets/inputs/date/GenericDatePicker/CustomInput.module.css"],"names":[],"mappings":"AAEI,0DACE,kBCDN,CDMI,2DAEE,kBAAA,CADA,SCHN,CDSA,kCAEE,kBAAA,CADA,mBAAA,CAEA,SAAA,CACA,kDCNF,CDSA,kCAGE,uFAAA,CADA,gDAAA,CADA,YCJF,CDYA,+BAKE,mGAAA,CAIA,WAAA,CASA,qCAAA,CALA,eAAA,CAZA,4EAAA,CAcA,yCAAA,CAJA,WAAA,CACA,YAAA,CAKA,2DAAA,CAHA,kBAAA,CAEA,sBAAA,CANA,UCPF,CDiBE,yCACE,kBCfJ,CDoBE,iCACE,SCjBJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": "jut__CustomInput__inputWrapper",
	"inputContainer": "jut__CustomInput__inputContainer",
	"controlFocused": "jut__CustomInput__controlFocused",
	"customInput": "jut__CustomInput__customInput",
	"icon": "jut__CustomInput__icon"
};
export default ___CSS_LOADER_EXPORT___;
