// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Icon__icon{height:1em;min-height:1em;min-width:1em;width:1em;fill:currentcolor}.jut__Icon__iconSizeSmall{font-size:var(--JDS-ICON-SIZE-SMALL,var(--GW-ICON-FONT-SIZE-1));line-height:var(--JDS-ICON-SIZE-SMALL,var(--GW-ICON-LINE-HEIGHT))}.jut__Icon__iconSizeMedium{font-size:var(--JDS-ICON-SIZE-MEDIUM,var(--GW-ICON-FONT-SIZE-2));line-height:var(--JDS-ICON-SIZE-MEDIUM,var(--GW-ICON-LINE-HEIGHT))}.jut__Icon__iconSizeLarge{font-size:var(--JDS-ICON-SIZE-LARGE,var(--GW-ICON-FONT-SIZE-3));line-height:var(--JDS-ICON-SIZE-LARGE,var(--GW-ICON-LINE-HEIGHT))}", "",{"version":3,"sources":["webpack://./../src/Icon.module.scss","webpack://./../../platform/@jutro/icons/Icon.module.css"],"names":[],"mappings":"AAAA,iBAGE,UAAA,CAFA,cAAA,CACA,aAAA,CAEA,SAAA,CACA,iBCCF,CDEA,0BAEE,+DAAA,CADA,iECEF,CDEA,2BAEE,gEAAA,CADA,kECEF,CDEA,0BAEE,+DAAA,CADA,iECEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "jut__Icon__icon",
	"iconSizeSmall": "jut__Icon__iconSizeSmall",
	"iconSizeMedium": "jut__Icon__iconSizeMedium",
	"iconSizeLarge": "jut__Icon__iconSizeLarge"
};
export default ___CSS_LOADER_EXPORT___;
