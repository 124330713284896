// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__BrandedLoader__screenReaderOnly{border:0;white-space:nowrap;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.jut__BrandedLoader__brandedLoader{height:var(--GW-BRANDED-LOADER-SIZE);width:var(--GW-BRANDED-LOADER-SIZE)}.jut__BrandedLoader__logoSegment{fill:var(--GW-BRANDED-LOADER-COLOR);opacity:0}.jut__BrandedLoader__fill1,.jut__BrandedLoader__fill2{animation-duration:var(--GW-BRANDED-LOADER-ANIMATION-DURATION);animation-iteration-count:infinite;animation-name:jut__BrandedLoader__hideshow;animation-timing-function:linear}.jut__BrandedLoader__fill2{animation-delay:calc(var(--GW-BRANDED-LOADER-ANIMATION-DURATION)/6.6)}@keyframes jut__BrandedLoader__hideshow{0%{opacity:0}20%{opacity:1}60%{opacity:1}80%{opacity:0}to{opacity:0}}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/loading/loader/BrandedLoader.module.scss","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss","webpack://./../../platform/@jutro/components/widgets/loading/loader/BrandedLoader.module.css"],"names":[],"mappings":"AAEA,sCC4BE,QAAA,CD3BA,kBAAA,CC4BA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA,CACA,SCxBF,CFLA,mCAEE,oCAAA,CADA,mCESF,CFLA,iCACE,mCAAA,CACA,SEQF,CFEA,sDALE,8DAAA,CAEA,kCAAA,CAHA,2CAAA,CAEA,gCEiBF,CFbA,2BAKE,qEEQF,CFLA,wCACE,GACE,SEQF,CFLA,IACE,SEOF,CFJA,IACE,SEMF,CFHA,IACE,SEKF,CFFA,GACE,SEIF,CACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screenReaderOnly": "jut__BrandedLoader__screenReaderOnly",
	"brandedLoader": "jut__BrandedLoader__brandedLoader",
	"logoSegment": "jut__BrandedLoader__logoSegment",
	"fill1": "jut__BrandedLoader__fill1",
	"fill2": "jut__BrandedLoader__fill2",
	"hideshow": "jut__BrandedLoader__hideshow"
};
export default ___CSS_LOADER_EXPORT___;
