// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__LogoTitle__logoTitle{align-items:center;display:flex;height:100%;margin-right:0}.jut__LogoTitle__title{color:var(--GW-LOGO-TITLE-COLOR);font-size:var(--GW-FONT-SIZE-LABEL);line-height:var(--GW-LINE-HEIGHT-LABEL);margin-left:var(--GW-SPACING-3);white-space:nowrap}@media (min-width:769px){.jut__LogoTitle__title{font-size:var(--GW-FONT-SIZE-H4);line-height:var(--GW-LINE-HEIGHT-H4)}}@media (min-width:1025px){.jut__LogoTitle__title{font-size:var(--GW-FONT-SIZE-H3);line-height:var(--GW-LINE-HEIGHT-H3)}}.jut__LogoTitle__logoTitle .jut__LogoTitle__logo{height:var(--GW-LOGO-TITLE-HEIGHT-PHONE);min-height:var(--GW-LOGO-TITLE-HEIGHT-PHONE);width:auto}@media (min-width:769px){.jut__LogoTitle__logoTitle .jut__LogoTitle__logo{height:var(--GW-LOGO-TITLE-HEIGHT-TABLET);min-height:var(--GW-LOGO-TITLE-HEIGHT-TABLET)}}@media (min-width:1025px){.jut__LogoTitle__logoTitle .jut__LogoTitle__logo{height:var(--GW-LOGO-TITLE-HEIGHT-DESKTOP);min-height:var(--GW-LOGO-TITLE-HEIGHT-DESKTOP)}}", "",{"version":3,"sources":["webpack://./../../../src/internal/LogoTitle/LogoTitle.module.scss","webpack://./../../platform/@jutro/components/internal/LogoTitle/LogoTitle.module.css","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAEA,2BAEE,kBAAA,CADA,YAAA,CAGA,WAAA,CADA,cCGF,CDCA,uBACE,gCAAA,CE0BA,mCAAA,CACA,uCAAA,CF1BA,+BAAA,CACA,kBCIF,CEiBI,yBHxBJ,uBEOE,gCAAA,CACA,oCDIA,CACF,CEWI,0BHxBJ,uBEEE,gCAAA,CACA,oCDeA,CACF,CDHA,iDACE,wCAAA,CAEA,4CAAA,CADA,UCOF,CEDI,yBHRJ,iDAMI,yCAAA,CACA,6CCOF,CACF,CEPI,0BHRJ,iDAWI,0CAAA,CACA,8CCQF,CACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoTitle": "jut__LogoTitle__logoTitle",
	"title": "jut__LogoTitle__title",
	"logo": "jut__LogoTitle__logo"
};
export default ___CSS_LOADER_EXPORT___;
