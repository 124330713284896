// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__StepProgressBar__stepProgressBar{display:flex;margin-bottom:var(--JDS-STEP-PROGRESS-BAR-MARGIN-BOTTOM,var(--JDS-SPACE-4,var(--GW-SPACING-4)));position:relative;width:var(--JDS-STEP-PROGRESS-BAR-WIDTH,100%)}.jut__StepProgressBar__vertical.jut__StepProgressBar__stepProgressBar{flex-direction:column}.jut__StepProgressBar__phoneTitle{box-sizing:border-box;overflow:hidden;padding:var(--JDS-STEP-PROGRESS-BAR-LABEL-PADDING,0 var(--JDS-SPACE-1,var(--GW-SPACING-1)));word-wrap:break-word;bottom:0;font-size:var(--JDS-STEP-PROGRESS-BAR-LABEL-FONT-SIZE,var(--JDS-FONT-LABEL-MEDIUM-FONT-SIZE,var(--GW-FONT-SIZE-LABEL)));line-height:var(--JDS-STEP-PROGRESS-BAR-LABEL-LINE-HEIGHT,var(--JDS-FONT-LABEL-MEDIUM-LINE-HEIGHT,var(--GW-LINE-HEIGHT-LABEL)));margin-bottom:var(--JDS-STEP-PROGRESS-BAR-LABEL-MARGIN-BOTTOM,var(--JDS-SPACE-4,var(--GW-SPACING-4)));text-align:center}.jut__StepProgressBar__titleDisabled{cursor:not-allowed;opacity:var(--JDS-STEP-PROGRESS-BAR-LABEL-OPACITY-DISABLED,var(--JDS-OPACITY-DISABLED,var(--GW-OPACITY-DISABLED)))}@media (max-width:580px){.jut__StepProgressBar__stepProgressBar:not(.jut__StepProgressBar__vertical){margin-bottom:0}}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/ProgressIndicator/StepProgress/StepProgressBar.module.scss","webpack://./../../platform/@jutro/components/widgets/ProgressIndicator/StepProgress/StepProgressBar.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAUA,uCAEE,YAAA,CAEA,+FAAA,CAHA,iBAAA,CAEA,6CCLF,CDYA,sEACE,qBCTF,CDYA,kCAEE,qBAAA,CADA,eAAA,CAEA,2FAAA,CAIA,oBAAA,CAEA,QAAA,CAKA,uHAAA,CAIA,+HAAA,CARA,qGAAA,CAFA,iBCRF,CDwBA,qCAKE,kBAAA,CAJA,kHCpBF,CCiBI,yBFWF,4EACE,eCxBF,CACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepProgressBar": "jut__StepProgressBar__stepProgressBar",
	"vertical": "jut__StepProgressBar__vertical",
	"phoneTitle": "jut__StepProgressBar__phoneTitle",
	"titleDisabled": "jut__StepProgressBar__titleDisabled"
};
export default ___CSS_LOADER_EXPORT___;
