// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__OldFieldMessage__fieldMessage{display:inline-block;font-size:var(--GW-FONT-SIZE-BODY);font-weight:var(--GW-FONT-WEIGHT-LIGHT);line-height:var(--GW-LINE-HEIGHT-BODY);margin-top:var(--GW-SPACING-1)}.jut__OldFieldMessage__fieldMessage:empty{display:none}.jut__OldFieldMessage__error{color:var(--GW-COLOR-ERROR)}.jut__OldFieldMessage__success{color:var(--GW-FIELD-COMPONENT-VALID-COLOR)}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/inputs/FieldComponent/OldFieldMessage.module.scss","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss","webpack://./../../platform/@jutro/components/widgets/inputs/FieldComponent/OldFieldMessage.module.css"],"names":[],"mappings":"AAEA,oCAGE,oBAAA,CCqBA,kCAAA,CDvBA,uCAAA,CCwBA,sCAAA,CDvBA,8BEKF,CFAE,0CACE,YEEJ,CFEA,6BACE,2BECF,CFEA,+BACE,2CECF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldMessage": "jut__OldFieldMessage__fieldMessage",
	"error": "jut__OldFieldMessage__error",
	"success": "jut__OldFieldMessage__success"
};
export default ___CSS_LOADER_EXPORT___;
