// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TopNavigation__topNavigation{background-color:var(--JDS-TOP-NAVIGATION-COLOR-BACKGROUND,var(--GW-TOP-NAV-BACKGROUND-COLOR));display:flex;flex-wrap:wrap;height:100%}.jut__TopNavigation__noWrap{flex-wrap:nowrap}.jut__TopNavigation__contextSwitcher{margin-right:var(--GW-SPACING-10)}", "",{"version":3,"sources":["webpack://./../../../src/NavBar/TopNavigation/TopNavigation.module.scss","webpack://./../../platform/@jutro/router/NavBar/TopNavigation/TopNavigation.module.css"],"names":[],"mappings":"AAAA,mCAIE,8FAAA,CAHA,YAAA,CAEA,cAAA,CADA,WCGF,CDKA,4BACE,gBCFF,CDKA,qCACE,iCCFF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topNavigation": "jut__TopNavigation__topNavigation",
	"noWrap": "jut__TopNavigation__noWrap",
	"contextSwitcher": "jut__TopNavigation__contextSwitcher"
};
export default ___CSS_LOADER_EXPORT___;
