// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__NotificationAction__popper{z-index:var(--GW-Z-INDEX-2)}.jut__NotificationAction__popoverContainer{width:var(--GW-NOTIFICATION-ACTION-SIZE)}.jut__NotificationAction__popoverTitle{max-width:calc(var(--GW-NOTIFICATION-ACTION-SIZE)*.65 - var(--GW-POPOVER-HEADER-PADDING)*2*.65 - var(--GW-POPOVER-HEADER-SPACING)*.65)}.jut__NotificationAction__popoverHeaderLink{max-width:calc(var(--GW-NOTIFICATION-ACTION-SIZE)*.35 - var(--GW-POPOVER-HEADER-PADDING)*2*.35 - var(--GW-POPOVER-HEADER-SPACING)*.35)}.jut__NotificationAction__popoverBody{height:unset;max-height:calc(var(--GW-NOTIFICATION-ACTION-SIZE) - var(--GW-POPOVER-HEADER-HEIGHT) - var(--GW-POPOVER-FOOTER-HEIGHT))}.jut__NotificationAction__popoverFooterLink{max-width:calc(var(--GW-NOTIFICATION-ACTION-SIZE) - var(--GW-POPOVER-FOOTER-PADDING)*2 - var(--GW-POPOVER-FOOTER-SPACING))}", "",{"version":3,"sources":["webpack://./../../../src/widgets/NotificationAction/NotificationAction.module.scss","webpack://./../../platform/@jutro/components/widgets/NotificationAction/NotificationAction.module.css"],"names":[],"mappings":"AAGA,iCACE,2BCFF,CDKA,2CACE,wCCFF,CDKA,uCAOE,sICRF,CDWA,4CAOE,sICdF,CDiBA,sCAME,YAAA,CALA,uHCbF,CDqBA,4CACE,0HClBF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popper": "jut__NotificationAction__popper",
	"popoverContainer": "jut__NotificationAction__popoverContainer",
	"popoverTitle": "jut__NotificationAction__popoverTitle",
	"popoverHeaderLink": "jut__NotificationAction__popoverHeaderLink",
	"popoverBody": "jut__NotificationAction__popoverBody",
	"popoverFooterLink": "jut__NotificationAction__popoverFooterLink"
};
export default ___CSS_LOADER_EXPORT___;
