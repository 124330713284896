// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Image__imageFigure{display:table}.jut__Image__imageFigureCaption{background-color:var(--GW-IMAGE-CAPTION-BACKGROUND-COLOR);caption-side:bottom;color:var(--GW-IMAGE-CAPTION-COLOR);display:table-caption;font-size:var(--GW-FONT-SIZE-SUBTEXT);padding:var(--GW-SPACING-1) var(--GW-SPACING-3);text-align:center}.jut__Image__imageFigureLoading{display:none}", "",{"version":3,"sources":["webpack://./../../../src/widgets/Image/Image.module.scss","webpack://./../../platform/@jutro/components/widgets/Image/Image.module.css"],"names":[],"mappings":"AAAA,yBACE,aCCF,CDEA,gCAIE,yDAAA,CAFA,mBAAA,CAGA,mCAAA,CAJA,qBAAA,CAMA,qCAAA,CADA,+CAAA,CAHA,iBCKF,CDEA,gCACE,YCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageFigure": "jut__Image__imageFigure",
	"imageFigureCaption": "jut__Image__imageFigureCaption",
	"imageFigureLoading": "jut__Image__imageFigureLoading"
};
export default ___CSS_LOADER_EXPORT___;
