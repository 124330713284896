// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__DataViewHeader__header{flex-wrap:nowrap;padding-bottom:var(--GW-SPACING-5);row-gap:var(--GW-SPACING-4)}.jut__DataViewHeader__header .jut__DataViewHeader__headerActions{margin-right:0;order:2}@media (max-width:768px){.jut__DataViewHeader__header .jut__DataViewHeader__headerActions{flex-grow:2}}.jut__DataViewHeader__wrappedHeader{flex-wrap:wrap}.jut__DataViewHeader__titleWithActions{flex-wrap:nowrap;row-gap:var(--GW-SPACING-4);width:100%}@media (max-width:580px){.jut__DataViewHeader__titleWithActions{flex-wrap:wrap}}.jut__DataViewHeader__controlsWrapper{-moz-column-gap:var(--GW-LAYOUT-1);column-gap:var(--GW-LAYOUT-1);flex-grow:1;flex-wrap:nowrap}@media (max-width:768px){.jut__DataViewHeader__controlsWrapper{width:100%}}", "",{"version":3,"sources":["webpack://./../../../../src/dataview/features/header/DataViewHeader.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/header/DataViewHeader.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAEA,6BAGE,gBAAA,CAFA,kCAAA,CACA,2BCGF,CDAE,iEACE,cAAA,CACA,OCEJ,CCmCI,yBFvCF,iEAKI,WCGJ,CACF,CDCA,oCACE,cCEF,CDCA,uCACE,gBAAA,CACA,2BAAA,CACA,UCEF,CCoBI,yBFzBJ,uCAMI,cCGF,CACF,CDAA,sCAGE,kCAAA,CAAA,6BAAA,CAFA,WAAA,CACA,gBCIF,CCSI,yBFfJ,sCAMI,UCIF,CACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "jut__DataViewHeader__header",
	"headerActions": "jut__DataViewHeader__headerActions",
	"wrappedHeader": "jut__DataViewHeader__wrappedHeader",
	"titleWithActions": "jut__DataViewHeader__titleWithActions",
	"controlsWrapper": "jut__DataViewHeader__controlsWrapper"
};
export default ___CSS_LOADER_EXPORT___;
