// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TextArea__textarea{cursor:inherit;height:auto;padding:var(--JDS-TEXT-AREA-FIELD-PADDING-INTERNAL);vertical-align:top;white-space:pre-wrap}.jut__TextArea__disabled{opacity:var(--JDS-OPACITY-DISABLED-INTERNAL)}.jut__TextArea__textAreaLabelTop{display:grid;grid-template-columns:auto max-content;grid-template-rows:auto;grid-gap:0;gap:0}.jut__TextArea__textAreaLabelLeft{display:grid}.jut__TextArea__counter{align-self:end;color:var(--JDS-COLOR-TEXT-SUBTLE-INTERNAL);font-family:var(--JDS-FONT-LABEL-SMALL-FONT-FAMILY-INTERNAL);font-size:var(--JDS-FONT-LABEL-SMALL-FONT-SIZE-INTERNAL);font-weight:var(--JDS-FONT-LABEL-SMALL-FONT-WEIGHT-INTERNAL);letter-spacing:var(--JDS-FONT-LABEL-SMALL-LETTER-SPACING-INTERNAL);line-height:var(--JDS-FONT-LABEL-SMALL-LINE-HEIGHT-INTERNAL);-webkit-text-decoration:var(--JDS-FONT-LABEL-SMALL-TEXT-DECORATION-INTERNAL);text-decoration:var(--JDS-FONT-LABEL-SMALL-TEXT-DECORATION-INTERNAL);text-transform:var(--JDS-FONT-LABEL-SMALL-TEXT-CASE-INTERNAL)}.jut__TextArea__disabledResize{resize:none}.jut__TextArea__autoResize{overflow:hidden}.jut__TextArea__displayOnly{white-space:pre-wrap}", "",{"version":3,"sources":["webpack://./../../../../src/components/fields/TextArea/TextArea.module.scss","webpack://./../../platform/@jutro/components/components/fields/TextArea/TextArea.module.css"],"names":[],"mappings":"AAAA,yBAGE,cAAA,CAFA,WAAA,CAGA,mDAAA,CACA,kBAAA,CAHA,oBCIF,CDEA,yBACE,4CCCF,CDEA,iCACE,YAAA,CACA,sCAAA,CACA,uBAAA,CACA,UAAA,CAAA,KCCF,CDEA,kCACE,YCCF,CDEA,wBACE,cAAA,CACA,2CAAA,CACA,4DAAA,CACA,wDAAA,CACA,4DAAA,CAIA,kEAAA,CAHA,4DAAA,CACA,4EAAA,CAAA,oEAAA,CACA,6DCEF,CDEA,+BACE,WCCF,CDEA,2BACE,eCCF,CDEA,4BACE,oBCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "jut__TextArea__textarea",
	"disabled": "jut__TextArea__disabled",
	"textAreaLabelTop": "jut__TextArea__textAreaLabelTop",
	"textAreaLabelLeft": "jut__TextArea__textAreaLabelLeft",
	"counter": "jut__TextArea__counter",
	"disabledResize": "jut__TextArea__disabledResize",
	"autoResize": "jut__TextArea__autoResize",
	"displayOnly": "jut__TextArea__displayOnly"
};
export default ___CSS_LOADER_EXPORT___;
