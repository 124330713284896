// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__SortingTrigger__icon{margin-left:var(--GW-SPACING-2)}.jut__SortingTrigger__icon.jut__SortingTrigger__inactive{opacity:var(--GW-OPACITY-DISABLED)}", "",{"version":3,"sources":["webpack://./../../../../../src/dataview/features/sorting/SortingTrigger/SortingTrigger.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/sorting/SortingTrigger/SortingTrigger.module.css"],"names":[],"mappings":"AAAA,2BACE,+BCCF,CDCE,yDACE,kCCCJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "jut__SortingTrigger__icon",
	"inactive": "jut__SortingTrigger__inactive"
};
export default ___CSS_LOADER_EXPORT___;
