// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TableTitleBar__tableTitleBar{align-items:center;background-color:var(--GW-BACKGROUND-COLOR);border-bottom:var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);display:flex;padding:var(--GW-SPACING-2) 0}.jut__TableTitleBar__title{color:var(--JDS-TABLE-TITLE-BAR-COLOR-TEXT,var(--GW-TABLE-TITLE-BAR-COLOR));flex:1 1;font-size:var(--GW-FONT-SIZE-H4);font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD);line-height:var(--GW-LINE-HEIGHT-H4)}.jut__TableTitleBar__left{text-align:left}.jut__TableTitleBar__right{text-align:right}.jut__TableTitleBar__center{text-align:center}.jut__TableTitleBar__titleAction{flex:0 1;margin-left:var(--GW-SPACING-2)}", "",{"version":3,"sources":["webpack://./../../../src/datatable/table/TableTitleBar.module.scss","webpack://./../../platform/@jutro/legacy/datatable/table/TableTitleBar.module.css","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss"],"names":[],"mappings":"AAEA,mCAGE,kBAAA,CAEA,2CAAA,CAJA,iEAAA,CACA,YAAA,CAEA,6BCGF,CDCA,2BACE,2EAAA,CAEA,QAAA,CEGA,gCAAA,CFJA,2CAAA,CEKA,oCDAF,CDCA,0BACE,eCEF,CDCA,2BACE,gBCEF,CDCA,4BACE,iBCEF,CDCA,iCACE,QAAA,CACA,+BCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableTitleBar": "jut__TableTitleBar__tableTitleBar",
	"title": "jut__TableTitleBar__title",
	"left": "jut__TableTitleBar__left",
	"right": "jut__TableTitleBar__right",
	"center": "jut__TableTitleBar__center",
	"titleAction": "jut__TableTitleBar__titleAction"
};
export default ___CSS_LOADER_EXPORT___;
