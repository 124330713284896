// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Columns__alignLeft{justify-content:flex-start;text-align:left}.jut__Columns__alignRight{justify-content:flex-end;text-align:right}.jut__Columns__alignCenter{justify-content:center;text-align:center}.jut__Columns__dateTimeCell{display:flex}.jut__Columns__dateTimeCellControl{min-width:unset}.jut__Columns__dateTimeCellControl div{font-weight:var(--GW-FONT-WEIGHT-REGULAR)}.jut__Columns__dropDownMenuContainer{min-width:var(--GW-LAYOUT-1)}.jut__Columns__actions{display:flex;justify-content:inherit;opacity:0}.jut__Columns__actions>:not(:first-child){margin-left:var(--GW-SPACING-5)}table.jut__TableView__table tbody tr:hover .jut__Columns__actions{opacity:1}.jut__Columns__actionsVisible{opacity:1}.jut__Columns__linkAction{font-weight:var(--GW-FONT-WEIGHT-BOLD)}.jut__Columns__iconAction{background-color:transparent}.jut__Columns__iconAction .jut__Columns__icon{color:var(--JDS-BUTTON-TERTIARY-COLOR-TEXT,var(--GW-BUTTON-TEXT-COLOR))}", "",{"version":3,"sources":["webpack://./../../../../src/dataview/features/columns/Columns.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/columns/Columns.module.css"],"names":[],"mappings":"AAAA,yBAEE,0BAAA,CADA,eCEF,CDEA,0BAEE,wBAAA,CADA,gBCEF,CDEA,2BAEE,sBAAA,CADA,iBCEF,CDEA,4BACE,YCCF,CDEA,mCACE,eCCF,CDCE,uCACE,yCCCJ,CDGA,qCACE,4BCAF,CDGA,uBACE,YAAA,CACA,uBAAA,CACA,SCAF,CDEE,0CACE,+BCAJ,CDKE,kEACE,SCFJ,CDMA,8BACE,SCHF,CDMA,0BACE,sCCHF,CDMA,0BACE,4BCHF,CDKE,8CACE,uECHJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignLeft": "jut__Columns__alignLeft",
	"alignRight": "jut__Columns__alignRight",
	"alignCenter": "jut__Columns__alignCenter",
	"dateTimeCell": "jut__Columns__dateTimeCell",
	"dateTimeCellControl": "jut__Columns__dateTimeCellControl",
	"dropDownMenuContainer": "jut__Columns__dropDownMenuContainer",
	"actions": "jut__Columns__actions",
	"actionsVisible": "jut__Columns__actionsVisible",
	"linkAction": "jut__Columns__linkAction",
	"iconAction": "jut__Columns__iconAction",
	"icon": "jut__Columns__icon"
};
export default ___CSS_LOADER_EXPORT___;
