// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__YearField__headerYearRange{flex-grow:1}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/inputs/date/YearField/YearField.module.scss","webpack://./../../platform/@jutro/components/widgets/inputs/date/YearField/YearField.module.css"],"names":[],"mappings":"AAEA,iCACE,WCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerYearRange": "jut__YearField__headerYearRange"
};
export default ___CSS_LOADER_EXPORT___;
