// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TreeNodeHeader__header{align-items:center;display:flex;min-width:0;padding:var(--GW-SPACING-3) var(--GW-SPACING-2)}.jut__TreeNodeHeader__header>:first-child:not(.jut__TreeNodeHeader__labelWrapper){flex:0 0 auto}.jut__TreeNodeHeader__labelWrapper{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.jut__TreeNodeHeader__iconMargin{margin-left:var(--GW-SPACING-2)}.jut__TreeNodeHeader__highlight{background-color:var(--GW-TREE-VIEW-HIGHLIGHT-BACKGROUND-COLOR)}", "",{"version":3,"sources":["webpack://./../../src/TreeView/TreeNodeHeader.module.scss","webpack://./../../platform/@jutro/components/TreeView/TreeNodeHeader.module.css"],"names":[],"mappings":"AAAA,6BAGE,kBAAA,CADA,YAAA,CAEA,WAAA,CAHA,+CCIF,CDCE,kFACE,aCCJ,CDGA,mCACE,eAAA,CACA,sBAAA,CACA,kBCAF,CDGA,iCACE,+BCAF,CDGA,gCACE,+DCAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "jut__TreeNodeHeader__header",
	"labelWrapper": "jut__TreeNodeHeader__labelWrapper",
	"iconMargin": "jut__TreeNodeHeader__iconMargin",
	"highlight": "jut__TreeNodeHeader__highlight"
};
export default ___CSS_LOADER_EXPORT___;
