"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
require("core-js/modules/web.dom-collections.iterator.js");
(function iconsEntry() {
  const importAll = requirement => {
    requirement.keys().forEach(requirement);
  };
  if (process.env.JUTRO_APP_CLIENT_ICONS_PATH) {
    importAll(require.context(process.env.JUTRO_APP_CLIENT_ICONS_PATH, true, /.*(gw|cust)-.*\.svg$/));
  }
  importAll(require.context('@jutro/components', true, /.*(gw|cust)-.*\.svg$/));
})();