// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Collapse__wrapper{position:relative}.jut__Collapse__outerContentWrapper{overflow:hidden}.jut__Collapse__outerContentWrapper.jut__Collapse__noOverflow{overflow:visible}.jut__Collapse__outerContentWrapper.jut__Collapse__overContent{position:absolute}.jut__Collapse__outerContentWrapper.jut__Collapse__hidden{visibility:hidden}.jut__Collapse__phantomBox{height:100%;position:absolute;right:0}.jut__Collapse__fullHeight{height:100%}.jut__Collapse__contentWrapper{overflow:hidden}.jut__Collapse__contentWrapper.jut__Collapse__noOverflow{overflow:visible}", "",{"version":3,"sources":["webpack://./../../../src/internal/collapse/Collapse.module.scss","webpack://./../../platform/@jutro/components/internal/collapse/Collapse.module.css"],"names":[],"mappings":"AAAA,wBACE,iBCCF,CDEA,oCACE,eCCF,CDCE,8DACE,gBCCJ,CDEE,+DACE,iBCAJ,CDGE,0DACE,iBCDJ,CDKA,2BAEE,WAAA,CADA,iBAAA,CAEA,OCFF,CDKA,2BACE,WCFF,CDKA,+BACE,eCFF,CDIE,yDACE,gBCFJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "jut__Collapse__wrapper",
	"outerContentWrapper": "jut__Collapse__outerContentWrapper",
	"noOverflow": "jut__Collapse__noOverflow",
	"overContent": "jut__Collapse__overContent",
	"hidden": "jut__Collapse__hidden",
	"phantomBox": "jut__Collapse__phantomBox",
	"fullHeight": "jut__Collapse__fullHeight",
	"contentWrapper": "jut__Collapse__contentWrapper"
};
export default ___CSS_LOADER_EXPORT___;
