// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TextAreaField__textArea{min-height:var(--GW-TEXTAREA-FIELD-MIN-HEIGHT);resize:vertical}.jut__TextAreaField__textArea,.jut__TextAreaField__textAreaReadOnly{height:auto;white-space:pre-wrap}", "",{"version":3,"sources":["webpack://./../../../src/components/TextAreaField/TextAreaField.module.scss","webpack://./../../platform/@jutro/legacy/components/TextAreaField/TextAreaField.module.css"],"names":[],"mappings":"AAAA,8BACE,8CAAA,CACA,eCGF,CDEA,oEAJE,WAAA,CACA,oBCMF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textArea": "jut__TextAreaField__textArea",
	"textAreaReadOnly": "jut__TextAreaField__textAreaReadOnly"
};
export default ___CSS_LOADER_EXPORT___;
