// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__MultipleSelect__container{height:auto;min-height:var(--JDS-INPUT-FIELD-HEIGHT-INTERNAL)}", "",{"version":3,"sources":["webpack://./../../../../../src/components/fields/dropdowns/MultipleSelect/MultipleSelect.module.scss","webpack://./../../platform/@jutro/components/components/fields/dropdowns/MultipleSelect/MultipleSelect.module.css"],"names":[],"mappings":"AAAA,gCACE,WAAA,CACA,iDCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jut__MultipleSelect__container"
};
export default ___CSS_LOADER_EXPORT___;
