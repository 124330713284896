// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__DateTimeField__fieldContainer{display:contents}.jut__DateTimeField__dateLabel{grid-column:1;grid-row:1}.jut__DateTimeField__dateField{grid-column:1;grid-row:2;min-width:10rem}.jut__DateTimeField__timeLabel{grid-column:2;grid-row:1}.jut__DateTimeField__timeField{grid-column:2;grid-row:2;min-width:7rem}", "",{"version":3,"sources":["webpack://./../../../../../src/widgets/inputs/date/DateTimeField/DateTimeField.module.scss","webpack://./../../platform/@jutro/components/widgets/inputs/date/DateTimeField/DateTimeField.module.css"],"names":[],"mappings":"AAEA,oCACE,gBCEF,CDCA,+BACE,aAAA,CACA,UCEF,CDCA,+BACE,aAAA,CACA,UAAA,CACA,eCEF,CDCA,+BACE,aAAA,CACA,UCEF,CDCA,+BACE,aAAA,CACA,UAAA,CACA,cCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldContainer": "jut__DateTimeField__fieldContainer",
	"dateLabel": "jut__DateTimeField__dateLabel",
	"dateField": "jut__DateTimeField__dateField",
	"timeLabel": "jut__DateTimeField__timeLabel",
	"timeField": "jut__DateTimeField__timeField"
};
export default ___CSS_LOADER_EXPORT___;
