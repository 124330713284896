// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ListViewColumn__column{flex-basis:10%;flex-grow:1;margin-bottom:var(--GW-LAYOUT-3);margin-top:var(--GW-LAYOUT-3);padding-right:var(--GW-SPACING-5)}.jut__ListViewColumn__actionsColumn{align-self:center}.jut__ListViewColumn__listViewBodyCell{font-weight:var(--GW-FONT-WEIGHT-BOLD)}", "",{"version":3,"sources":["webpack://./../../../../src/dataview/ListView/ListViewItem/ListViewColumn.module.scss","webpack://./../../platform/@jutro/legacy/dataview/ListView/ListViewItem/ListViewColumn.module.css"],"names":[],"mappings":"AAAA,6BAIE,cAAA,CACA,WAAA,CAHA,gCAAA,CADA,6BAAA,CAEA,iCCGF,CDEA,oCACE,iBCCF,CDEA,uCACE,sCCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": "jut__ListViewColumn__column",
	"actionsColumn": "jut__ListViewColumn__actionsColumn",
	"listViewBodyCell": "jut__ListViewColumn__listViewBodyCell"
};
export default ___CSS_LOADER_EXPORT___;
