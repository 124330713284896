// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__FieldIcon__fieldWithIcon{position:relative;width:100%}.jut__FieldIcon__fieldWithIcon input:not(:first-child){padding-left:calc(.75rem + var(--GW-ICON-FONT-SIZE-1) + var(--GW-SPACING-3))}.jut__FieldIcon__fieldWithIcon input:not(:last-child){padding-right:calc(var(--GW-SPACING-3) + var(--GW-ICON-FONT-SIZE-1) + .75rem)}.jut__FieldIcon__iconWrapper,svg.jut__FieldIcon__iconWrapper{left:.75rem;position:absolute;text-align:inherit;top:50%;transform:translateY(-50%)}.jut__FieldIcon__iconWrapper:last-child,svg.jut__FieldIcon__iconWrapper:last-child{left:auto;right:.75rem}.jut__FieldIcon__icon,svg.jut__FieldIcon__icon{color:var(--JDS-INPUT-FIELD-TEXT-COLOR-TEXT,var(--GW-FIELD-COMPONENT-COLOR));pointer-events:none;vertical-align:middle}.jut__FieldIcon__icon.disabled,svg.jut__FieldIcon__icon.disabled{opacity:var(--GW-OPACITY-DISABLED)}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/inputs/FieldComponent/FieldIcon.module.scss","webpack://./../../platform/@jutro/components/widgets/inputs/FieldComponent/FieldIcon.module.css"],"names":[],"mappings":"AAIA,+BACE,iBAAA,CACA,UCAF,CDGI,uDACE,4ECDN,CDMI,sDACE,6ECJN,CDWA,6DAME,WA3BU,CAuBV,iBAAA,CACA,kBAAA,CACA,OAAA,CACA,0BCPF,CDUE,mFACE,SAAA,CACA,YCPJ,CDWA,+CAEE,4EAAA,CAKA,mBAAA,CADA,qBCVF,CDaE,iEACE,kCCVJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldWithIcon": "jut__FieldIcon__fieldWithIcon",
	"iconWrapper": "jut__FieldIcon__iconWrapper",
	"icon": "jut__FieldIcon__icon"
};
export default ___CSS_LOADER_EXPORT___;
