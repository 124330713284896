// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "i.jut__QuickViewHeader__icon,svg.jut__QuickViewHeader__icon{flex-shrink:0;font-size:var(--GW-QUICK-VIEW-HEADER-ICON-SIZE)}i.jut__QuickViewHeader__noLabelIcon,svg.jut__QuickViewHeader__noLabelIcon{font-size:calc(var(--GW-QUICK-VIEW-HEADER-ICON-SIZE) - var(--GW-FONT-SIZE-SUBTEXT))}.jut__QuickViewHeader__label{color:var(--GW-TEXT-COLOR-2);font-size:var(--GW-FONT-SIZE-SUBTEXT);line-height:var(--GW-LINE-HEIGHT-SUBTEXT)}.jut__QuickViewHeader__title,.jut__QuickViewHeader__titleWrapper{overflow:hidden}.jut__QuickViewHeader__title{font-size:var(--GW-FONT-SIZE-H4);font-weight:var(--GW-FONT-WEIGHT-SEMI-BOLD);line-height:var(--GW-LINE-HEIGHT-H4);text-overflow:ellipsis;white-space:nowrap}.jut__QuickViewHeader__noLabelTitle{font-size:var(--GW-FONT-SIZE-H3);line-height:var(--GW-LINE-HEIGHT-H3)}", "",{"version":3,"sources":["webpack://./../../../src/quick-view/QuickViewHeader/QuickViewHeader.module.scss","webpack://./../../platform/@jutro/legacy/quick-view/QuickViewHeader/QuickViewHeader.module.css","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss"],"names":[],"mappings":"AAEA,4DAGE,aAAA,CADA,+CCGF,CDCA,0EAEE,mFCEF,CDGA,6BAGE,4BAAA,CEaA,qCAAA,CACA,yCDdF,CDOA,iEAHE,eCSF,CDNA,6BETE,gCAAA,CFYA,2CAAA,CEXA,oCAAA,CFaA,sBAAA,CACA,kBCAF,CDGA,oCEvBE,gCAAA,CACA,oCDwBF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "jut__QuickViewHeader__icon",
	"noLabelIcon": "jut__QuickViewHeader__noLabelIcon",
	"label": "jut__QuickViewHeader__label",
	"title": "jut__QuickViewHeader__title",
	"titleWrapper": "jut__QuickViewHeader__titleWrapper",
	"noLabelTitle": "jut__QuickViewHeader__noLabelTitle"
};
export default ___CSS_LOADER_EXPORT___;
