// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__StickyFooter__stickyFooter{background-color:var(--GW-STICKY-FOOTER-BACKGROUND-COLOR);bottom:0;box-shadow:var(--GW-SHADOW-UP);position:sticky}.jut__StickyFooter__fullWidth{left:0;position:fixed;right:0}", "",{"version":3,"sources":["webpack://./../../../src/components/StickyFooter/StickyFooter.module.scss","webpack://./../../platform/@jutro/legacy/components/StickyFooter/StickyFooter.module.css"],"names":[],"mappings":"AAAA,iCAGE,yDAAA,CADA,QAAA,CAEA,8BAAA,CAHA,eCIF,CDEA,8BAEE,MAAA,CADA,cAAA,CAEA,OCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stickyFooter": "jut__StickyFooter__stickyFooter",
	"fullWidth": "jut__StickyFooter__fullWidth"
};
export default ___CSS_LOADER_EXPORT___;
