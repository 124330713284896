// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__NumberInput__numberInput{position:relative}.jut__NumberInput__numberInput input[type=number]::-webkit-inner-spin-button,.jut__NumberInput__numberInput input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;appearance:none;margin:0}.jut__NumberInput__numberInput input[type=number]{-webkit-appearance:textfield;-moz-appearance:textfield;appearance:textfield}.jut__NumberInput__numberInputButtons{display:flex;flex-direction:column;gap:4px;height:100%;justify-content:center;position:absolute;right:12px;top:0}.jut__NumberInput__numberInputButtons .jut__NumberInput__numberInputButton{height:10px;padding:0;width:20px}", "",{"version":3,"sources":["webpack://./../../../../src/components/fields/NumberInput/NumberInput.module.scss","webpack://./../../platform/@jutro/components/components/fields/NumberInput/NumberInput.module.css"],"names":[],"mappings":"AAAA,+BACE,iBCCF,CDCE,0JAEE,uBAAA,CAAA,eAAA,CACA,QCCJ,CDEE,kDACE,4BAAA,CAAA,yBAAA,CAAA,oBCAJ,CDIA,sCAGE,YAAA,CACA,qBAAA,CAGA,OAAA,CADA,WAAA,CADA,sBAAA,CAJA,iBAAA,CAOA,UAAA,CANA,KCKF,CDIE,2EACE,WAAA,CAEA,SAAA,CADA,UCDJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberInput": "jut__NumberInput__numberInput",
	"numberInputButtons": "jut__NumberInput__numberInputButtons",
	"numberInputButton": "jut__NumberInput__numberInputButton"
};
export default ___CSS_LOADER_EXPORT___;
