// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__ModalNext__modal{background:var(--GW-MODAL-BACKGROUND-COLOR);border:var(--JDS-MODAL-BORDER-WIDTH,1px) solid var(--JDS-MODAL-BORDER-COLOR,transparent);border-radius:var(--JDS-MODAL-BORDER-RADIUS,var(--GW-BORDER-RADIUS));box-shadow:var(--JDS-MODAL-SHADOW,var(--GW-SHADOW-2));max-height:95vh;max-width:95vw;min-height:0;min-width:25vw;outline:none;position:absolute}@media (max-width:1024px){.jut__ModalNext__modal{min-width:35vw}}@media (max-width:768px){.jut__ModalNext__modal{min-width:55vw}}@media (max-width:580px){.jut__ModalNext__modal{min-width:85vw}}.jut__ModalNext__modalLayout{max-height:inherit}.jut__ModalNext__overlay{align-items:center;background-color:var(--GW-MODAL-OVERLAY-BACKGROUND-COLOR);display:flex;inset:0;justify-content:center;min-height:100vh;min-width:100vw;padding:var(--GW-LAYOUT-6) var(--GW-LAYOUT-6);position:fixed;z-index:var(--GW-Z-INDEX-4)}", "",{"version":3,"sources":["webpack://./../../../src/widgets/modal-next/ModalNext.module.scss","webpack://./../../platform/@jutro/components/widgets/modal-next/ModalNext.module.css","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/breakpoints-mixins.scss"],"names":[],"mappings":"AAOA,uBACE,2CAAA,CACA,wFAAA,CAEA,oEAAA,CACA,qDAAA,CAMA,eAAA,CADA,cAAA,CADA,YAAA,CADA,cAAA,CAFA,YAAA,CACA,iBCAF,CCgCI,0BFvCJ,uBAcI,cCHF,CACF,CC2BI,yBFvCJ,uBAkBI,cCFF,CACF,CCsBI,yBFvCJ,uBAsBI,cCDF,CACF,CDIA,6BACE,kBCDF,CDIA,yBASE,kBAAA,CANA,yDAAA,CAEA,YAAA,CAHA,OAAA,CAMA,sBAAA,CAFA,gBAAA,CACA,eAAA,CAGA,6CAAA,CATA,cAAA,CAGA,2BCKF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "jut__ModalNext__modal",
	"modalLayout": "jut__ModalNext__modalLayout",
	"overlay": "jut__ModalNext__overlay"
};
export default ___CSS_LOADER_EXPORT___;
