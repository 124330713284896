// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__Breadcrumb__breadcrumb{display:inline-block}.jut__Breadcrumb__breadcrumb:empty{display:none}.jut__Breadcrumb__breadcrumb ul{margin:var(--JDS-BREADCRUMB-LIST-MARGIN,0);padding:var(--JDS-BREADCRUMB-LIST-PADDING,0)}.jut__Breadcrumb__breadcrumb li{display:inline}.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__breadcrumbLink,.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__currentLink{padding:var(--JDS-BREADCRUMB-LINK-PADDING,var(--JDS-SPACE-1,var(--GW-SPACING-1)) var(--JDS-SPACE-2,var(--GW-SPACING-2)));vertical-align:middle}.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__breadcrumbLink,.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__breadcrumbLink span,.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__breadcrumbLink span:hover,.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__breadcrumbLink:hover,.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__currentLink,.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__currentLink span,.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__currentLink span:hover,.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__currentLink:hover{font-size:var(--JDS-BREADCRUMB-FONT-SIZE,var(--GW-FONT-SIZE-BODY))}.jut__Breadcrumb__breadcrumb .jut__Breadcrumb__divider{color:var(--JDS-BREADCRUMB-DIVIDER-COLOR-TEXT,var(--GW-BREADCRUMB-DIVIDER-COLOR));font-size:var(--JDS-BREADCRUMB-DIVIDER-FONT-SIZE,var(--GW-FONT-SIZE-H4));margin:var(--JDS-BREADCRUMB-DIVIDER-MARGIN,var(--JDS-SPACE-2,var(--GW-SPACING-2)));vertical-align:middle}", "",{"version":3,"sources":["webpack://./../../src/Breadcrumb/Breadcrumb.module.scss","webpack://./../../platform/@jutro/router/Breadcrumb/Breadcrumb.module.css"],"names":[],"mappings":"AAOA,6BACE,oBCNF,CDQE,mCACE,YCNJ,CDSE,gCAEE,0CAAA,CADA,4CCNJ,CDUE,gCACE,cCRJ,CDWE,yHAGE,wHAAA,CADA,qBCPJ,CDuBM,ghBACE,kECZR,CDiBE,uDAOE,iFAAA,CALA,wEAAA,CACA,kFAAA,CAFA,qBCZJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": "jut__Breadcrumb__breadcrumb",
	"breadcrumbLink": "jut__Breadcrumb__breadcrumbLink",
	"currentLink": "jut__Breadcrumb__currentLink",
	"divider": "jut__Breadcrumb__divider"
};
export default ___CSS_LOADER_EXPORT___;
