// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__SearchInput__searchField{min-width:var(--GW-FILTER-BAR-INPUT-MIN-WIDTH)}.jut__SearchInput__fullWidthSearchField{flex:1 1}.jut__SearchInput__fullWidthSearchFieldControl{min-width:var(--GW-DATA-VIEWS-SEARCH-MIN-WIDTH)}", "",{"version":3,"sources":["webpack://./../../../../../src/dataview/features/search/SearchInput/SearchInput.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/search/SearchInput/SearchInput.module.css"],"names":[],"mappings":"AAAA,+BACE,8CCCF,CDEA,wCACE,QCCF,CDEA,+CACE,+CCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchField": "jut__SearchInput__searchField",
	"fullWidthSearchField": "jut__SearchInput__fullWidthSearchField",
	"fullWidthSearchFieldControl": "jut__SearchInput__fullWidthSearchFieldControl"
};
export default ___CSS_LOADER_EXPORT___;
