// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__RadioGroup__wrapper{border:none;margin:0;padding:0}.jut__RadioGroup__control{padding:var(--JDS-RADIO-GROUP-PADDING-INTERNAL)}.jut__RadioGroup__control.jut__RadioGroup__horizontal{display:flex;flex-wrap:wrap;gap:var(--JDS-RADIO-GROUP-GAP-HORIZONTAL-INTERNAL)}.jut__RadioGroup__control.jut__RadioGroup__vertical{display:flex;flex-direction:column;gap:var(--JDS-RADIO-GROUP-GAP-VERTICAL-INTERNAL)}.jut__RadioGroup__messages{margin-top:var(--JDS-RADIO-GROUP-GAP-VERTICAL-INTERNAL)}.jut__RadioGroup__label{align-items:flex-start}", "",{"version":3,"sources":["webpack://./../../../../src/components/fields/RadioGroup/RadioGroup.module.scss","webpack://./../../platform/@jutro/components/components/fields/RadioGroup/RadioGroup.module.css"],"names":[],"mappings":"AAAA,0BAGE,WAAA,CAFA,QAAA,CACA,SCEF,CDGA,0BACE,+CCAF,CDEE,sDACE,YAAA,CACA,cAAA,CACA,kDCAJ,CDGE,oDACE,YAAA,CACA,qBAAA,CACA,gDCDJ,CDKA,2BACE,uDCFF,CDKA,wBACE,sBCFF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "jut__RadioGroup__wrapper",
	"control": "jut__RadioGroup__control",
	"horizontal": "jut__RadioGroup__horizontal",
	"vertical": "jut__RadioGroup__vertical",
	"messages": "jut__RadioGroup__messages",
	"label": "jut__RadioGroup__label"
};
export default ___CSS_LOADER_EXPORT___;
