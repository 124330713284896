// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__FieldColumn__fullWidth{width:100%}", "",{"version":3,"sources":["webpack://./../../../src/datatable/columns/FieldColumn.module.scss","webpack://./../../platform/@jutro/legacy/datatable/columns/FieldColumn.module.css"],"names":[],"mappings":"AAAA,6BACE,UCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "jut__FieldColumn__fullWidth"
};
export default ___CSS_LOADER_EXPORT___;
