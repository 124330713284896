// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TreeViewNoResults__noResults{padding:0 var(--GW-SPACING-4) var(--GW-SPACING-3);text-align:center}", "",{"version":3,"sources":["webpack://./../../src/TreeView/TreeViewNoResults.module.scss","webpack://./../../platform/@jutro/components/TreeView/TreeViewNoResults.module.css"],"names":[],"mappings":"AAAA,mCAEE,iDAAA,CADA,iBCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noResults": "jut__TreeViewNoResults__noResults"
};
export default ___CSS_LOADER_EXPORT___;
