// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TableColumn__textRight{text-align:right}.jut__TableColumn__textLeft{text-align:left}.jut__TableColumn__textCenter{text-align:center}.jut__TableColumn__tableCell{border-bottom:0 solid var(--GW-BORDER-COLOR);font-size:var(--GW-FONT-SIZE-BODY);line-height:var(--GW-LINE-HEIGHT-BODY);padding:var(--GW-SPACING-3) var(--GW-SPACING-4);vertical-align:top}", "",{"version":3,"sources":["webpack://./../../../src/datatable/table/TableColumn.module.scss","webpack://./../../platform/@jutro/legacy/datatable/table/TableColumn.module.css","webpack://./../../../../../node_modules/@jutro/theme/assets/sass/mixins/typography-mixins.scss"],"names":[],"mappings":"AAEA,6BACE,gBCEF,CDCA,4BACE,eCEF,CDCA,8BACE,iBCEF,CDCA,6BACE,4CAAA,CEWA,kCAAA,CACA,sCAAA,CFXA,+CAAA,CACA,kBCIF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textRight": "jut__TableColumn__textRight",
	"textLeft": "jut__TableColumn__textLeft",
	"textCenter": "jut__TableColumn__textCenter",
	"tableCell": "jut__TableColumn__tableCell"
};
export default ___CSS_LOADER_EXPORT___;
