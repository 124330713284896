// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__StateOverlay__stateOverlay{min-height:227px;padding:var(--GW-LAYOUT-4)}", "",{"version":3,"sources":["webpack://./../../../../src/dataview/features/overlay/StateOverlay.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/overlay/StateOverlay.module.css"],"names":[],"mappings":"AAAA,iCACE,gBAAA,CACA,0BCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stateOverlay": "jut__StateOverlay__stateOverlay"
};
export default ___CSS_LOADER_EXPORT___;
