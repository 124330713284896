// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TreeNode__node{align-items:center;border:1px solid transparent;cursor:pointer;display:flex;justify-content:normal}.jut__TreeNode__node:hover:not(.jut__TreeNode__disabled){background:var(--GW-TREE-VIEW-NODE-BACKGROUND-COLOR-HOVER);color:var(--GW-TREE-VIEW-NODE-COLOR-HOVER)}.jut__TreeNode__node.jut__TreeNode__disabled{cursor:not-allowed;opacity:var(--GW-OPACITY-DISABLED)}:focus>.jut__TreeNode__node:not(.jut__TreeNode__disabled){border:var(--GW-TREE-VIEW-NODE-BORDER);box-shadow:0 0 0 2px var(--GW-FOCUS-COLOR-LIGHT);outline:none}.jut__TreeNode__nodeWrapper{list-style-type:none}.jut__TreeNode__nodeWrapper:focus-visible{outline:none}.jut__TreeNode__selected{background-color:var(--GW-TREE-VIEW-NODE-BACKGROUND-COLOR-SELECTED);color:var(--GW-TREE-VIEW-NODE-COLOR-SELECTED)}.jut__TreeNode__icon{flex:0 0 auto;transform-origin:center;transition:transform var(--GW-ANIMATION-DURATION)}.jut__TreeNode__iconExpanded{transform:rotate(90deg)}.jut__TreeNode__iconHidden{visibility:hidden}", "",{"version":3,"sources":["webpack://./../../src/TreeView/TreeNode.module.scss","webpack://./../../platform/@jutro/components/TreeView/TreeNode.module.css","webpack://./../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss"],"names":[],"mappings":"AAEA,qBAGE,kBAAA,CAEA,4BAAA,CADA,cAAA,CAHA,YAAA,CACA,sBCKF,CDAE,yDACE,0DAAA,CACA,0CCEJ,CDCE,6CAEE,kBAAA,CADA,kCCEJ,CDEE,0DAGE,sCAAA,CELA,gDAPU,CAEZ,YDUF,CDIA,4BACE,oBCDF,CDGE,0CACE,YCDJ,CDKA,yBACE,mEAAA,CACA,6CCFF,CDKA,qBAGE,aAAA,CADA,uBAAA,CADA,iDCAF,CDKA,6BACE,uBCFF,CDKA,2BACE,iBCFF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"node": "jut__TreeNode__node",
	"disabled": "jut__TreeNode__disabled",
	"nodeWrapper": "jut__TreeNode__nodeWrapper",
	"selected": "jut__TreeNode__selected",
	"icon": "jut__TreeNode__icon",
	"iconExpanded": "jut__TreeNode__iconExpanded",
	"iconHidden": "jut__TreeNode__iconHidden"
};
export default ___CSS_LOADER_EXPORT___;
