// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TableAdapter__titleRight{text-align:right}.jut__TableAdapter__titleLeft{text-align:left}.jut__TableAdapter__titleCenter{text-align:center}", "",{"version":3,"sources":["webpack://./../../../../src/dataview/DataViewAdapters/table/TableAdapter.module.scss","webpack://./../../platform/@jutro/legacy/dataview/DataViewAdapters/table/TableAdapter.module.css"],"names":[],"mappings":"AAAA,+BACE,gBCCF,CDEA,8BACE,eCCF,CDEA,gCACE,iBCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleRight": "jut__TableAdapter__titleRight",
	"titleLeft": "jut__TableAdapter__titleLeft",
	"titleCenter": "jut__TableAdapter__titleCenter"
};
export default ___CSS_LOADER_EXPORT___;
