// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__FilteringModal__filterItem{min-width:var(--GW-FILTER-BAR-INPUT-MIN-WIDTH)}.jut__FilteringModal__triggerButton{color:var(--GW-TEXT-COLOR-1);position:relative}.jut__FilteringModal__triggerButton:focus,.jut__FilteringModal__triggerButton:hover{background-color:var(--GW-CLICKABLE-ELEMENT-BACKGROUND-COLOR-ACTIVE)}.jut__FilteringModal__triggerButton:not(:disabled){cursor:pointer}.jut__FilteringModal__triggerButton .jut__FilteringModal__buttonIcon{font-size:var(--GW-ICON-FONT-SIZE-3)}.jut__FilteringModal__controlButton{margin-top:var(--GW-SPACING-4)}.jut__FilteringModal__filtersBadge{background-color:var(--GW-COLOR-INFO);border-radius:50%;height:var(--GW-SPACING-4);position:absolute;right:0;top:0;width:var(--GW-SPACING-4)}", "",{"version":3,"sources":["webpack://./../../../../../src/dataview/features/filtering/FilteringModal/FilteringModal.module.scss","webpack://./../../platform/@jutro/legacy/dataview/features/filtering/FilteringModal/FilteringModal.module.css"],"names":[],"mappings":"AAAA,iCACE,8CCCF,CDEA,oCAEE,4BAAA,CADA,iBCEF,CDCE,oFAEE,oECAJ,CDGE,mDACE,cCDJ,CDIE,qEACE,oCCFJ,CDMA,oCACE,8BCHF,CDMA,mCAGE,qCAAA,CACA,iBAAA,CAHA,0BAAA,CAIA,iBAAA,CAEA,OAAA,CADA,KAAA,CAJA,yBCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterItem": "jut__FilteringModal__filterItem",
	"triggerButton": "jut__FilteringModal__triggerButton",
	"buttonIcon": "jut__FilteringModal__buttonIcon",
	"controlButton": "jut__FilteringModal__controlButton",
	"filtersBadge": "jut__FilteringModal__filtersBadge"
};
export default ___CSS_LOADER_EXPORT___;
