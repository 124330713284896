// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__QuickViewFooter__quickViewFooter{background-color:var(--GW-QUICK-VIEW-FOOTER-BACKGROUND-COLOR);bottom:0;flex:0 0 auto;height:var(--GW-QUICK-VIEW-FOOTER-HEIGHT);left:0;padding:var(--GW-SPACING-4) var(--GW-LAYOUT-6);position:sticky}.jut__QuickViewFooter__quickViewFooterFlex{height:100%}.jut__QuickViewFooter__cancelButton,.jut__QuickViewFooter__saveButton{height:var(--GW-QUICK-VIEW-FOOTER-BUTTON-HEIGHT);width:var(--GW-QUICK-VIEW-FOOTER-BUTTON-WIDTH)}", "",{"version":3,"sources":["webpack://./../../../src/quick-view/QuickViewFooter/QuickViewFooter.module.scss","webpack://./../../platform/@jutro/legacy/quick-view/QuickViewFooter/QuickViewFooter.module.css"],"names":[],"mappings":"AAAA,uCAME,6DAAA,CAHA,QAAA,CAIA,aAAA,CANA,yCAAA,CAGA,MAAA,CACA,8CAAA,CAHA,eCMF,CDEA,2CACE,WCCF,CDEA,sEAGE,gDAAA,CADA,8CCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quickViewFooter": "jut__QuickViewFooter__quickViewFooter",
	"quickViewFooterFlex": "jut__QuickViewFooter__quickViewFooterFlex",
	"cancelButton": "jut__QuickViewFooter__cancelButton",
	"saveButton": "jut__QuickViewFooter__saveButton"
};
export default ___CSS_LOADER_EXPORT___;
