// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__TooltipIcon__tooltipIcon{background-color:var(--JDS-TOOLTIP-ICON-TRIGGER-ICON-CONTAINER-COLOR-BACKGROUND,transparent);border-color:var(--JDS-TOOLTIP-ICON-TRIGGER-ICON-CONTAINER-COLOR-BORDER,0);border-radius:var(--JDS-TOOLTIP-ICON-TRIGGER-ICON-CONTAINER-BORDER-RADIUS,var(--GW-BORDER-RADIUS-FULL));border-style:solid;border-width:var(--JDS-TOOLTIP-ICON-TRIGGER-ICON-CONTAINER-BORDER-WIDTH,0);color:var(--JDS-TOOLTIP-ICON-TRIGGER-COLOR-ICON,var(--GW-TOOLTIP-ICON-COLOR));display:inline-flex;outline:none;padding:var(--JDS-TOOLTIP-ICON-TRIGGER-ICON-CONTAINER-PADDING,0);position:relative}.jut__TooltipIcon__tooltipIcon:focus{box-shadow:var(--JDS-TOOLTIP-ICON-SHADOW-FOCUS,0 0 0 2px var(--JDS-UTILITY-COLOR-SHADOW-FOCUS-REGULAR,var(--GW-FOCUS-COLOR-LIGHT)))}.jut__TooltipIcon__left,.jut__TooltipIcon__top{position:absolute}.jut__TooltipIcon__left{align-items:center;height:var(--JDS-INPUT-FIELD-HEIGHT,var(--GW-FIELD-COMPONENT-HEIGHT));right:calc(var(--JDS-SPACE-8, var(--GW-SPACING-8))*-1)}.jut__TooltipIcon__disabled{cursor:not-allowed;opacity:var(--JDS-TOOLTIP-ICON-OPACITY-DISABLED,var(--JDS-OPACITY-DISABLED,var(--GW-OPACITY-DISABLED)))}", "",{"version":3,"sources":["webpack://./../../../src/widgets/Tooltip/TooltipIcon.module.scss","webpack://./../../platform/@jutro/components/widgets/Tooltip/TooltipIcon.module.css"],"names":[],"mappings":"AAKA,+BAOE,4FAAA,CAYA,0EAAA,CANA,uGAAA,CAIA,kBAAA,CACA,0EAAA,CAjBA,6EAAA,CAKA,mBAAA,CAKA,YAAA,CANA,gEAAA,CAOA,iBCHF,CDYE,qCACE,mICVJ,CDyBA,+CAHE,iBCXF,CDcA,wBAIE,kBAAA,CADA,qEAAA,CADA,sDChBF,CDqBA,4BAKE,kBAAA,CAJA,uGCjBF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipIcon": "jut__TooltipIcon__tooltipIcon",
	"left": "jut__TooltipIcon__left",
	"top": "jut__TooltipIcon__top",
	"disabled": "jut__TooltipIcon__disabled"
};
export default ___CSS_LOADER_EXPORT___;
