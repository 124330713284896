// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__InlineLoader__inlineLoader{display:block}.jut__InlineLoader__withMessage{display:inline;margin-left:var(--GW-SPACING-2)}.jut__InlineLoader__withLoadingMessage{color:var(--GW-INLINE-LOADER-SPINNER-COLOR)}.jut__InlineLoader__withSuccessMessage{color:var(--GW-COLOR-SUCCESS)}.jut__InlineLoader__loaderIcon{font-size:medium;height:1.5em;line-height:inherit;vertical-align:bottom}.mir.jut__InlineLoader__loaderIcon{font-size:medium;height:1.5em;line-height:inherit;vertical-align:bottom}.jut__InlineLoader__screenReaderOnly{border:0;white-space:nowrap;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}", "",{"version":3,"sources":["webpack://./../../../../src/widgets/loading/inlineLoader/InlineLoader.module.scss","webpack://./../../platform/@jutro/components/widgets/loading/inlineLoader/InlineLoader.module.css","webpack://./../../../../../../node_modules/@jutro/theme/assets/sass/mixins/common-mixins.scss"],"names":[],"mappings":"AAEA,iCAEE,aCCF,CDEA,gCACE,cAAA,CACA,+BCCF,CDEA,uCACE,2CCCF,CDEA,uCACE,6BCCF,CDEA,+BAEE,gBAAA,CAGA,YAAA,CADA,mBAAA,CADA,qBCEF,CDIE,mCAEE,gBAAA,CAGA,YAAA,CADA,mBAAA,CADA,qBCAJ,CDMA,qCERE,QAAA,CFSA,kBAAA,CERA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA,CACA,SDOF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineLoader": "jut__InlineLoader__inlineLoader",
	"withMessage": "jut__InlineLoader__withMessage",
	"withLoadingMessage": "jut__InlineLoader__withLoadingMessage",
	"withSuccessMessage": "jut__InlineLoader__withSuccessMessage",
	"loaderIcon": "jut__InlineLoader__loaderIcon",
	"screenReaderOnly": "jut__InlineLoader__screenReaderOnly"
};
export default ___CSS_LOADER_EXPORT___;
