// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__PhoneNumberField__phoneNumber{content:normal}", "",{"version":3,"sources":["webpack://./../../../src/components/PhoneNumberField/PhoneNumberField.module.scss","webpack://./../../platform/@jutro/legacy/components/PhoneNumberField/PhoneNumberField.module.css"],"names":[],"mappings":"AAAA,oCACE,cCCF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneNumber": "jut__PhoneNumberField__phoneNumber"
};
export default ___CSS_LOADER_EXPORT___;
